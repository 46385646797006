import { Route, Routes } from "react-router-dom"

const Hpme = () => {
    return (
        <div className="h-screen bg-neutral">a</div>
    )
}

const Admin = () => {
    return (
        <div className="h-screen bg-neutral">a</div>
    )
}

export default Admin